<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { data } = this.formOptions;
                    data["parentId"] = this.currNode.id;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "resource",
            title: "Banner图",
            minWidth: 160,
            editRender: {
              name: "$VxePreview",
              props: {
                maxlength: 24,
                placeholder: "请选择banner图"
              }
            }
          },
          {
            field: "clientType",
            title: "客户端",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                maxlength: 24,
                placeholder: "请选择客户端"
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            }
          }
        ],
        editConfig: {
          enabled: false,
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "resource",
            title: "上传图片",
            span: 24,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["resource"] = data;
                }
              }
            }
          },
          {
            field: "clientType",
            title: "客户端",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                placeholder: "请选择客户端",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "120",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          resource: [{ required: true, message: "请上传图片" }],
          clientType: [{ required: true, message: "请选择客户端" }],
          enabled: [{ required: true, message: "请上传图片" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: '',
          resource: "",
          clientType: "",
          enabled: "1",
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "clientType",
            title: "客户端",
            itemRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                placeholder: "请选择客户端",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          clientType: "",
          enabled: ""
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
